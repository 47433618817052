import axios from 'axios';

import { getCAPEligibilityURL } from 'common/settings';
import { CAP_APPLICATION_PAGE, CAP_APP_ERROR_MSG } from 'common/constants';

export const CLEAR_CAP_POST_RESPONSE = 'CLEAR_CAP_POST_RESPONSE';
export const CAP_POST_SUCCESS = 'CAP_POST_SUCCESS';
export const CAP_POST_FAILURE = 'CAP_POST_FAILURE';

export function capApplicationSuccess(instance, data) {
    return {
        type: CAP_POST_SUCCESS,
        instance,
        data,
    };
}

export function capApplicationFailure(response, instance) {
    return {
        type: CAP_POST_FAILURE,
        response,
        instance,
    };
}

export function clearCAPPostResponse(instance) {
    return {
        type: CLEAR_CAP_POST_RESPONSE,
        instance,
    };
}

export function submitCAPApplication(parcelId, data) {
    return dispatch =>
        axios
            .post(getCAPEligibilityURL(parcelId), data)
            .then(() =>
                dispatch(capApplicationSuccess(CAP_APPLICATION_PAGE, data)),
            )
            .catch(() =>
                dispatch(
                    capApplicationFailure(
                        CAP_APP_ERROR_MSG,
                        CAP_APPLICATION_PAGE,
                    ),
                ),
            );
}
