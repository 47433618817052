import { combineReducers } from 'redux';

import { mapLayers, rates } from 'common/reducers';
import { search } from 'common/geocoder/reducers';
import walkthroughListPage from './walkthrough/reducers';
import { capApplicationPage } from './cap/reducers';
import { explorePage } from './explore/reducers';
import { detailsPage } from './details/reducers';
import appheader from './appheader/reducers';

export default combineReducers({
    explorePage,
    detailsPage,
    walkthroughListPage,
    search,
    capApplicationPage,
    mapLayers,
    rates,
    appheader,
});
