/* eslint global-require:0 */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux';

const middlewares = [thunk, routerMiddleware(browserHistory)];

if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({ diff: true, collapsed: true });
    middlewares.push(logger);
}

export default applyMiddleware(...middlewares)(createStore);
