import { createAction } from 'redux-act';
import axios from 'axios';

import { getSearchUrl } from 'common/settings';
import { preprocessResults } from 'common/utils';

export const updateUnityBarSearchQuery = createAction(
    'UPDATE_UNITY_BAR_SEARCH_QUERY',
);
export const clearUnityBarSearchQuery = createAction(
    'CLEAR_UNITY_BAR_SEARCH_QUERY',
);

export const startGeocoderSearch = createAction('START_NEW_GEOCODER_SEARCH');
export const failGeocoderSearch = createAction('FAIL_NEW_GEOCODER_SEARCH');
export const completeGeocoderSearch = createAction(
    'COMPLETE_NEW_GEOCODER_SEARCH',
);
export const clearGeocoderSearch = createAction('CLEAR_NEW_GEOCODER_SEARCH');

export const resetGeocoder = createAction('RESET_NEW_GEOCODER');

export function performUnityBarGeocoderSearch() {
    return (dispatch, getState) => {
        dispatch(startGeocoderSearch());

        const {
            appheader: {
                unitybar: { query },
            },
        } = getState();

        return axios
            .get(getSearchUrl(query))
            .then(({ data }) => preprocessResults(data))
            .then(data => dispatch(completeGeocoderSearch(data)))
            .catch(err => dispatch(failGeocoderSearch(err)));
    };
}
