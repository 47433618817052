export const PARCELS_OVERLAY = 'parcelsOverlay';
export const IMPERVIOUS_OVERLAY = 'imperviousOverlay';
export const STREETS_OVERLAY = 'streetsOverlay';
export const ID_SEARCH = 'ID_SEARCH';
export const XY_SEARCH = 'XY_SEARCH';
export const CAP_SEARCH = 'CAP_SEARCH';
export const CAP_DETAIL_SEARCH = 'CAP_DETAIL_SEARCH';
export const HOME_HEADER = 'HOME_HEADER';
export const HOME_BODY = 'HOME_BODY';
export const EXPLORE_PAGE = 'EXPLORE_PAGE';
export const DETAILS_PAGE = 'DETAILS_PAGE';
export const WALKTHROUGH_HEADER = 'WALKTHROUGH_HEADER';
export const WALKTHROUGH_LIST = 'WALKTHROUGH_LIST';
export const APPEALS_HEADER = 'APPEALS_HEADER';
export const HELP_HEADER = 'HELP_HEADER';
export const CREDITS_HEADER = 'CREDITS_HEADER';
export const CAP_HEADER = 'CAP_HEADER';
export const CAP_PAGE = 'CAP_PAGE';
export const CAP_APPLICATION_PAGE = 'CAP_APPLICATION';
export const NO_PARCEL_CLICK_MSG = "Couldn't find a parcel where you clicked.";
export const NO_PARCEL_ID_MSG = "Couldn't find a parcel with that ID.";
export const CREATE_NO_PARCEL_ID_MSG = id =>
    `Couldn't find a parcel with ID ${id}.`;
export const NO_CAP_ELIGIBILITY_MSG =
    "Couldn't find CAP eligibility details for that parcel.";
export const CREATE_NO_ACCOUNT_ID_MSG = (parcelId, accountId) =>
    `Couldn't find an account with ID ${accountId} at parcel ID ${parcelId}.`;
export const CREATE_INELIGIBLE_ACCOUNT_ID_MSG = id =>
    `Account ${id} is not eligible for CAP.`;
export const CAP_APP_ERROR_MSG =
    'Your CAP application could not be submitted. ' +
    'Please contact pwd.stormwaterappeals@phila.gov.';
export const APPEALS_FORM = 'appeals_form';
export const APPEALS_PROCESS = 'appeals_process';
export const RES = 'res';
export const NONRES = 'nonres';
export const CONDO = 'condo';
export const GARDEN = 'exemptcommunitygarden';
export const EXEMPT = 'Exempt';
export const CEMETERY = 'Cemetery';
export const RES_DISPLAY = 'Residential';
export const NONRES_DISPLAY = 'Non-Residential';
export const CONDO_DISPLAY = 'Condominium';
export const GARDEN_DISPLAY = 'Community Garden';
export const ADDRESS_UNAVAILABLE = 'Address Unavailable';

export const TOP_LEFT = 'topleft';
export const BOTTOM_LEFT = 'bottomleft';
export const BOTTOM_RIGHT = 'bottomright';

export const LEGAL_DISCLAIMER_TEXT = `
    Please Note: The data on this site is to be used for stormwater
    billing purposes only, and is not intended to depict, and should
    not be relied upon to determine, the legal boundaries of
    parcels. The City of Philadelphia Records Department is
    exclusively responsible for maintaining the official parcel maps
    of the City, which are the graphical depictions of the legal
    descriptions contained on deeds that are also processed by the
    Records Department. The Streets Department’s Survey Unit
    maintains maps that contain survey data and street plans.`;
