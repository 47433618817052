import flow from 'lodash/flow';
import initial from 'lodash/initial';
import last from 'lodash/last';

// Compare relevant fields from two `RateSchedule`s to determine whether they
// differ for display purposes
function ratePeriodsHaveDifferentCharges(
    { RateSchedule: first },
    { RateSchedule: second },
) {
    return (
        first.ResidenceCharge !== second.ResidenceCharge ||
        first.GrossUnitCharge !== second.GrossUnitCharge ||
        first.ImperviousUnitCharge !== second.ImperviousUnitCharge ||
        first.ParcelWeightFactor !== second.ParcelWeightFactor ||
        first.ResidenceBillingCharge !== second.ResidenceBillingCharge ||
        first.NonResidenceBillingCharge !== second.NonResidenceBillingCharge
    );
}

// Reduce a list of rate periods sorted in ascending date order into a list of
// rate ranges, also sorted in ascending order
function transformRatePeriodsIntoRateRanges(accountCharges) {
    return accountCharges.reduce((acc, next) => {
        const previousCharge = last(acc);

        if (
            !acc.length ||
            ratePeriodsHaveDifferentCharges(previousCharge, next)
        ) {
            return acc.concat(next);
        }

        const adjustedPreviousCharge = Object.assign({}, previousCharge, {
            RateSchedule: Object.assign({}, previousCharge.RateSchedule, {
                EndDate: next.RateSchedule.EndDate,
                IsCurrent:
                    previousCharge.RateSchedule.IsCurrent ||
                    next.RateSchedule.IsCurrent,
            }),
        });

        return initial(acc).concat(adjustedPreviousCharge);
    }, []);
}

// Restructure input to move the `ParcelTotals` object out of an object context
const formatParcelChargesForCreatingRateRanges = ({ ParcelTotals }) =>
    ParcelTotals;

// Find the end date for the currently active rate range to set the rate range
// to set the initially selected option in the rate range dropdown
const findCurrentlyActiveRateRangeEndDate = ranges =>
    ranges.find(({ RateSchedule: { IsCurrent } }) => IsCurrent).RateSchedule
        .EndDate;

// Restructure input to place `RateSchedule` objects within an object context to
// make them work with the transformation function
const formatRateScheduleDataToCreateRanges = data =>
    data.map(RateSchedule => ({ RateSchedule }));

// Restructure input to move the `RateSchedule` objects out of an object context
const formatRateRanges = data => data.map(({ RateSchedule }) => RateSchedule);

// Restructure `RateSchedule` input, then transform it into rate ranges before
// restoring it to its original structure
const transformRateScheduleIntoRateRanges = flow(
    formatRateScheduleDataToCreateRanges,
    transformRatePeriodsIntoRateRanges,
    formatRateRanges,
);

/**
 * Get the end date for the currently active rate range from a set of rate
 * ranges collected into buckets of identical adjacent charges.
 *
 * @param {object} chargeSummary -  Parcel charge summary.
 * @returns {string} - End date of the currently active rate period from the
 *                     list of account charges grouped into ranges of identical
 *                     adjacent rate periods.
 */
export const getCurrentRateRangeEndDate = flow(
    formatParcelChargesForCreatingRateRanges,
    transformRatePeriodsIntoRateRanges,
    findCurrentlyActiveRateRangeEndDate,
);

/**
 * Transforms parcel details response data to replace lists containing rate
 * periods into lists containing rate ranges which fuse adjacent identical
 * rate periods into distinct ranges.
 *
 * @param {object} details - Parcel Details response from the PWD WebAPI
 * @returns {details} - Parcel Details data with its rate period data fused
 *                      into ranges for which the rates are identical.
 */
export function replaceAPIResponseRatePeriodsWithRanges(details) {
    return Object.assign({}, details, {
        ChargeSummary: Object.assign({}, details.ChargeSummary, {
            ParcelAccounts: details.ChargeSummary.ParcelAccounts.map(account =>
                Object.assign({}, account, {
                    YearlyAccountCharges: transformRatePeriodsIntoRateRanges(
                        account.YearlyAccountCharges,
                    ),
                }),
            ),
            ParcelTotals: transformRatePeriodsIntoRateRanges(
                details.ChargeSummary.ParcelTotals,
            ),
            RateSchedules: transformRateScheduleIntoRateRanges(
                details.ChargeSummary.RateSchedules,
            ),
        }),
    });
}
