import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import parseWkt from 'wellknown';
import moment from 'moment';

export function locationDisplayName(location) {
    return `${location.Parcel.Address} - ${location.Parcel.Owner1}`;
}

export function wktToGeoJSON(wkt) {
    const geojson = parseWkt(wkt);

    return geojson;
}

export function isInteger(x) {
    return x === parseInt(x, 10);
}

export function formatInt(x) {
    return x.toLocaleString('en-US');
}

export function formatCurrency(x) {
    return x.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function formatDateString(ds) {
    return moment(ds).format('MMM D YYYY');
}

export function formatMobileDateString(ds) {
    return moment(ds).format('M/D/YYYY');
}

export function findCAPAccountIndex(accountNumber, capAccounts) {
    return findIndex(capAccounts, 'AccountNumber', accountNumber);
}

export function parseWaterAccountNumber(account) {
    return account.substring(3).replace(/-/g, '');
}

/**
 * Perform clean-up on geocoder results
 * @param {array} locations - geocoder results
 */
export function preprocessResults(locations) {
    // If there are multiple results that are not associated
    // with a parcel, just use the first one.
    if (locations.length > 1 && !locations[0].Parcel) {
        return [locations[0]];
    }

    return locations;
}

/**
 * Remove all results if the first result doesn't
 * contain parcel information
 * @param {array} locations - geocoder results
 */
export function discardResultsIfNoParcel(locations) {
    // If the first results doesn't have parcel information,
    // it's likely that the rest of the results don't have
    // parcel informatin either, or are not good matches
    // for the search term. In these cases, we discard all
    // of the results so that the user is prompted to try
    // a different search term.
    if (locations.length >= 1 && !locations[0].Parcel) {
        return [];
    }

    return locations;
}

export function getSortedAccountCharges(accountIndex, chargeSummary) {
    const yearlyAccountCharges =
        chargeSummary.ParcelAccounts[accountIndex].YearlyAccountCharges;

    yearlyAccountCharges.sort(
        (a, b) =>
            Date.parse(b.RateSchedule.EndDate) -
            Date.parse(a.RateSchedule.EndDate),
    );

    return yearlyAccountCharges;
}

export function getCurrentRatePeriod(chargeSummary) {
    if (
        chargeSummary &&
        chargeSummary.ParcelAccounts &&
        chargeSummary.ParcelAccounts.length
    ) {
        const charges = getSortedAccountCharges(0, chargeSummary);
        const currentCharge =
            find(charges, charge => charge.RateSchedule.IsCurrent) ||
            charges[0];
        return currentCharge.RateSchedule.EndDate;
    }
    return null;
}

export function formatAccountAddress({
    StreetDir: streetDirection,
    StreetName: streetName,
    HouseNum: houseNumber,
}) {
    return streetDirection
        ? `${houseNumber} ${streetDirection} ${streetName}`
        : `${houseNumber} ${streetName}`;
}
