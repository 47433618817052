export const SET_ZOOM = 'SET_ZOOM';
export const SET_CENTER = 'SET_CENTER';
export const CLEAR_MAP = 'CLEAR_MAP';

export function clearMap() {
    return {
        type: CLEAR_MAP,
    };
}

export function setZoom(zoom) {
    return {
        type: SET_ZOOM,
        zoom,
    };
}

export function setCenter(center) {
    return {
        type: SET_CENTER,
        center,
    };
}
