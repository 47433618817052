import { createReducer } from 'redux-act';
import update from 'immutability-helper';

import {
    updateUnityBarSearchQuery,
    clearUnityBarSearchQuery,
    startGeocoderSearch,
    failGeocoderSearch,
    completeGeocoderSearch,
    clearGeocoderSearch,
    resetGeocoder,
} from './actions';

const initialState = Object.freeze({
    unitybar: Object.freeze({
        query: '',
    }),
    data: null,
    fetching: false,
    error: null,
});

export default createReducer(
    {
        [updateUnityBarSearchQuery]: (state, payload) =>
            update(state, {
                unitybar: {
                    query: { $set: payload },
                },
                error: { $set: null },
            }),
        [clearUnityBarSearchQuery]: state =>
            update(state, {
                unitybar: {
                    query: { $set: initialState.unitybar.query },
                },
                error: { $set: null },
            }),
        [startGeocoderSearch]: state =>
            update(state, {
                data: { $set: null },
                fetching: { $set: true },
            }),
        [failGeocoderSearch]: (state, payload) =>
            update(state, {
                data: { $set: null },
                fetching: { $set: false },
                error: { $set: payload },
            }),
        [completeGeocoderSearch]: (state, payload) =>
            update(state, {
                data: { $set: payload },
                fetching: { $set: false },
                error: { $set: null },
            }),
        [clearGeocoderSearch]: state =>
            update(state, {
                data: { $set: null },
                fetching: { $set: false },
                error: { $set: null },
            }),
        [resetGeocoder]: () => initialState,
    },
    initialState,
);
