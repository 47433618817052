import { WALKTHROUGH_LIST } from 'common/constants';
import { COMPLETE_SEARCH, START_SEARCH } from 'common/geocoder/actions';
import { CLEAR_WALKTHROUGH_PARCEL_TYPE } from './actions';

const initialState = {
    parcel: null,
};

export default function walkthroughListPage(
    state = initialState,
    { instance, location, type },
) {
    if (instance === WALKTHROUGH_LIST) {
        switch (type) {
            case COMPLETE_SEARCH:
                return Object.assign({}, state, {
                    parcel: location.Parcel,
                });
            case START_SEARCH:
            default:
                return initialState;
        }
    }
    if (type === CLEAR_WALKTHROUGH_PARCEL_TYPE) {
        return initialState;
    }
    return state;
}
