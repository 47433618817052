import {
    ADD_MAP_LAYER,
    REMOVE_MAP_LAYER,
    START_SET_MAPSERVER_INDEXES,
    COMPLETE_SET_MAPSERVER_INDEXES,
    FAIL_SET_MAPSERVER_INDEXES,
    COMPLETE_FETCH_CURRENT_RATES,
} from './actions';
import {
    PARCELS_OVERLAY,
    IMPERVIOUS_OVERLAY,
    STREETS_OVERLAY,
} from './constants';

export const initMapOverlays = {
    parcelsOverlay: true,
    imperviousOverlay: true,
    exemptOverlay: false,
    streetsOverlay: true,
    parcelsOverlayIndex: -1,
    imperviousOverlayIndex: -1,
    searchingForOverlayIndexes: false,
};

export function mapLayers(state = initMapOverlays, action) {
    if (action.type === START_SET_MAPSERVER_INDEXES) {
        return Object.assign({}, state, {
            searchingForOverlayIndexes: true,
        });
    }
    if (action.type === COMPLETE_SET_MAPSERVER_INDEXES) {
        return Object.assign({}, state, {
            parcelsOverlayIndex: action.parcelsOverlayIndex,
            imperviousOverlayIndex: action.imperviousOverlayIndex,
            searchingForOverlayIndexes: false,
        });
    }
    if (action.type === FAIL_SET_MAPSERVER_INDEXES) {
        return Object.assign({}, state, {
            parcelsOverlayIndex: -1,
            imperviousOverlayIndex: -1,
            searchingForOverlayIndexes: false,
        });
    }
    if (action.type === ADD_MAP_LAYER || action.type === REMOVE_MAP_LAYER) {
        const toggle = action.type === ADD_MAP_LAYER;
        if (action.layer === PARCELS_OVERLAY) {
            return Object.assign({}, state, {
                parcelsOverlay: toggle,
            });
        }
        if (action.layer === IMPERVIOUS_OVERLAY) {
            return Object.assign({}, state, {
                imperviousOverlay: toggle,
            });
        }
        if (action.layer === STREETS_OVERLAY) {
            return Object.assign({}, state, {
                streetsOverlay: toggle,
            });
        }
    }
    return state;
}

const initRatesState = {
    current: null,
};

export function rates(state = initRatesState, action) {
    if (action.type === COMPLETE_FETCH_CURRENT_RATES) {
        return Object.assign({}, state, {
            current: action.rates,
        });
    }
    return state;
}
