import { CLEAR_CAP_AND_DETAILS, SET_CAP_ACCOUNT_INDEX } from 'common/actions';

import {
    ID_SEARCH,
    XY_SEARCH,
    CAP_SEARCH,
    CAP_DETAIL_SEARCH,
    DETAILS_PAGE,
    CAP_PAGE,
} from 'common/constants';

import {
    GEOCODER_SEARCH,
    START_SEARCH,
    COMPLETE_SEARCH,
} from 'common/geocoder/actions';

import {
    SET_PREVIEW_PARCEL,
    CLEAR_PREVIEW_PARCEL,
    SET_RATE_PERIOD,
    CLEAR_DETAILS_STATE_EXCEPT_CAP,
    CLEAR_CAP_DETAILS,
} from './actions';

import {
    getCurrentRateRangeEndDate,
    replaceAPIResponseRatePeriodsWithRanges,
} from './helpers';

export const initDetailsPageState = Object.freeze({
    capParcelDetails: null,
    details: null,
    capAccountIndex: 0,
    capEligibility: null,
    markerPosition: null,
    previewParcel: null,
    selectedRatePeriod: null,
});

export function detailsPage(state = initDetailsPageState, action) {
    if (action.type === CLEAR_DETAILS_STATE_EXCEPT_CAP) {
        return Object.assign({}, initDetailsPageState, {
            capParcelDetails: state.capParcelDetails,
            capAccountIndex: state.capAccountIndex,
            capEligibility: state.capEligibility,
        });
    }

    if (action.type === CLEAR_CAP_DETAILS) {
        return Object.assign({}, state, {
            capParcelDetails: initDetailsPageState.capParcelDetails,
            capAccountIndex: initDetailsPageState.capAccountIndex,
            capEligibility: initDetailsPageState.capEligibility,
        });
    }

    if (
        action.type === COMPLETE_SEARCH &&
        (action.instance === DETAILS_PAGE || action.instance === CAP_PAGE)
    ) {
        if (action.searchType === ID_SEARCH) {
            return Object.assign({}, state, {
                details: replaceAPIResponseRatePeriodsWithRanges(
                    action.location,
                ),
                capParcelDetails: action.location,
                markerPosition: null,
                capEligibility: null,
                selectedRatePeriod: getCurrentRateRangeEndDate(
                    action.location.ChargeSummary,
                ),
            });
        }
        if (action.searchType === CAP_DETAIL_SEARCH) {
            return Object.assign({}, state, {
                details: replaceAPIResponseRatePeriodsWithRanges(
                    action.location,
                ),
                capParcelDetails: action.location,
                selectedRatePeriod: getCurrentRateRangeEndDate(
                    action.location.ChargeSummary,
                ),
            });
        }
        if (
            action.searchType === GEOCODER_SEARCH &&
            action.instance === CAP_PAGE
        ) {
            return Object.assign({}, state, {
                capEligibility: null,
                capParcelDetails: action.location,
            });
        }
        if (action.searchType === CAP_SEARCH) {
            return Object.assign({}, state, {
                capEligibility: action.location,
            });
        }
    } else if (action.type === SET_CAP_ACCOUNT_INDEX) {
        return Object.assign({}, state, {
            capAccountIndex: action.capAccountIndex,
        });
    } else if (
        action.type === START_SEARCH &&
        action.instance === DETAILS_PAGE &&
        action.searchType === XY_SEARCH
    ) {
        return Object.assign({}, state, {
            markerPosition: {
                lat: action.lat,
                lng: action.lng,
            },
        });
    } else if (
        action.type === START_SEARCH &&
        action.searchType === GEOCODER_SEARCH &&
        action.instance === CAP_PAGE
    ) {
        return Object.assign({}, state, {
            capEligibility: null,
        });
    } else if (action.type === CLEAR_CAP_AND_DETAILS) {
        return initDetailsPageState;
    } else if (action.type === SET_PREVIEW_PARCEL) {
        return Object.assign({}, state, {
            previewParcel: action.location.Parcel,
        });
    } else if (action.type === CLEAR_PREVIEW_PARCEL) {
        return Object.assign({}, state, {
            previewParcel: null,
        });
    } else if (action.type === SET_RATE_PERIOD) {
        return Object.assign({}, state, {
            selectedRatePeriod: action.value,
        });
    }

    return state;
}
