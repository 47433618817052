/* eslint-disable react/jsx-filename-extension */
import 'core-js';
import 'dom4';
import '../../sass/main.scss';
import '../../index.html';

import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import {
    applyRouterMiddleware,
    Router,
    Route,
    browserHistory,
} from 'react-router';
import { useScroll } from 'react-router-scroll';

import {
    homeRoute,
    homeRouteWithoutSlash,
    mapRoute,
    mapLatLngRoute,
    mapParcelIDRoute,
    parcelIDRoute,
    helpRoute,
    helpBillingRoute,
    helpBillingPropertyTypeRoute,
    helpAppealsRoute,
    helpAppealsFileRoute,
    helpCreditsRoute,
    capRoute,
    capParcelIdAccountIdRoute,
    noMatchRoute,
} from 'common/routes';
import createStoreWithMiddleware from './store';
import mainReducer from './reducer';

const ExploreContainer = lazy(() => import('./explore'));
const DetailsContainer = lazy(() => import('./details'));
const HomeContainer = lazy(() => import('./home'));
const WalkthroughContainer = lazy(() => import('./walkthrough'));
const HelpContainer = lazy(() => import('./help'));
const AppealsContainer = lazy(() => import('./appeals'));
const CreditsContainer = lazy(() => import('./credits'));
const NoRouteMatchContainer = lazy(() =>
    import('common/containers/NoRouteMatch'),
);
const CapContainer = lazy(() => import('./cap'));
const CapApplicationContainer = lazy(() =>
    import('./cap/components/CapApplication'),
);

const store = createStoreWithMiddleware(
    mainReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && // eslint-disable-line no-underscore-dangle
        window.__REDUX_DEVTOOLS_EXTENSION__(), // eslint-disable-line no-underscore-dangle
);

const makeLoadableComponent = LazilyLoadedComponent => props => (
    <Suspense fallback={null}>
        <LazilyLoadedComponent {...props} />
    </Suspense>
);

const LoadableHomeContainer = makeLoadableComponent(HomeContainer);
const LoadableExploreContainer = makeLoadableComponent(ExploreContainer);
const LoadableDetailsContainer = makeLoadableComponent(DetailsContainer);
const LoadableHelpContainer = makeLoadableComponent(HelpContainer);
const LoadableWalkthroughContainer = makeLoadableComponent(
    WalkthroughContainer,
);
const LoadableAppealsContainer = makeLoadableComponent(AppealsContainer);
const LoadableCreditsContainer = makeLoadableComponent(CreditsContainer);
const LoadableCapContainer = makeLoadableComponent(CapContainer);
const LoadableNoRouteMatchContainer = makeLoadableComponent(
    NoRouteMatchContainer,
);
const LoadableCapApplicationContainer = makeLoadableComponent(
    CapApplicationContainer,
);

render(
    <Provider store={store}>
        <Router
            history={browserHistory}
            render={applyRouterMiddleware(useScroll())}
        >
            <Route path={homeRoute} component={LoadableHomeContainer} />
            <Route
                path={homeRouteWithoutSlash}
                component={LoadableHomeContainer}
            />
            <Route path={mapRoute} component={LoadableExploreContainer} />
            <Route path={mapLatLngRoute} component={LoadableExploreContainer} />
            <Route
                path={mapParcelIDRoute}
                component={LoadableExploreContainer}
            />
            <Route path={parcelIDRoute} component={LoadableDetailsContainer} />
            <Route path={helpRoute} component={LoadableHelpContainer} />
            <Route
                path={helpBillingRoute}
                component={LoadableWalkthroughContainer}
            />
            <Route
                path={helpBillingPropertyTypeRoute}
                component={LoadableWalkthroughContainer}
            />
            <Route
                path={helpAppealsRoute}
                component={LoadableAppealsContainer}
            />
            <Route
                path={helpAppealsFileRoute}
                component={LoadableAppealsContainer}
            />
            <Route
                path={helpCreditsRoute}
                component={LoadableCreditsContainer}
            />
            <Route path={capRoute} component={LoadableCapContainer} />
            <Route
                path={capParcelIdAccountIdRoute}
                component={LoadableCapApplicationContainer}
            />
            <Route
                path={noMatchRoute}
                component={LoadableNoRouteMatchContainer}
            />
        </Router>
    </Provider>,
    document.getElementById('mount'),
);
