import trimEnd from 'lodash/trimEnd';
import { rootUrl } from 'common/settings';

export const createRootRelativeRoute = route =>
    `${trimEnd(rootUrl, '/')}${route}`;

export const homeRoute = createRootRelativeRoute('/');
export const homeRouteWithoutSlash = createRootRelativeRoute('');
export const mapRoute = createRootRelativeRoute('/map');
export const mapLatLngRoute = createRootRelativeRoute('/map/:lat/:lng');
export const mapParcelIDRoute = createRootRelativeRoute('/map/:parcelId');
export const parcelIDRoute = createRootRelativeRoute('/parcel/:parcelId');

export const createParcelDetailsRouteFromParcelID = id =>
    createRootRelativeRoute(`/parcel/${id}`);

export const createMapRouteFromLatLng = (lat, lng) =>
    createRootRelativeRoute(`/map/${lat}/${lng}`);

export const helpRoute = createRootRelativeRoute('/help');

export const helpBillingRoute = createRootRelativeRoute('/help/billing');
export const helpBillingPropertyTypeRoute = createRootRelativeRoute(
    '/help/billing/:propertyType',
);

export const helpBillingResRoute = createRootRelativeRoute('/help/billing/res');
export const helpBillingNonResRoute = createRootRelativeRoute(
    '/help/billing/nonres',
);
export const helpBillingCondoRoute = createRootRelativeRoute(
    '/help/billing/condo',
);

export const createBillingRouteFromPropertyType = propertyType =>
    createRootRelativeRoute(`/help/billing/${propertyType}`);

export const helpAppealsRoute = createRootRelativeRoute('/help/appeals');
export const helpAppealsFileRoute = createRootRelativeRoute(
    '/help/appeals/file',
);
export const helpCreditsRoute = createRootRelativeRoute('/help/credits');

export const capRoute = createRootRelativeRoute('/cap');
export const capParcelIdAccountIdRoute = createRootRelativeRoute(
    '/cap/:parcelId/:accountId',
);
export const createCapParcelIdAccountIdRouteFromIds = (parcelId, accountId) =>
    createRootRelativeRoute(`/cap/${parcelId}/${accountId}`);

export const noMatchRoute = createRootRelativeRoute('/*');
