import includes from 'lodash/includes';

const internalEnvs = ['pwdTest', 'pwdProd', 'pwdProdNew', 'azavea'];
const baseUrl = process.env.API_URL;
export const pvDataMapServerURL = process.env.MAP_DATA_URL;
export const pvDataMapServerJSONURL = `${pvDataMapServerURL}?f=json`;
export const pvStreetsMapServerURL = process.env.MAP_STREETS_URL;
export const internalTrackerUrl = process.env.CREDIT_APPEALS_URL;
export const pvOrthoMapURL = process.env.ORTHO_URL;
export const rootUrl = process.env.ROOT_URL;
export const useInternalFeatures = includes(
    internalEnvs,
    process.env.INSTALL_ENV,
);
export const maxZoomLevel = 22;
export const minZoomLevel = 12;
export const maxBounds = [[39.84228, -75.31814], [40.1626, -74.90478]];
export const parcelOutlineColor = '#F36C52';

export function getSearchUrl(query) {
    return `${baseUrl}/search/?q=${encodeURIComponent(query)}`;
}

export function getXYSearchUrl(lat, lng) {
    return `${baseUrl}/search/?x=${lng}&y=${lat}`;
}

export function getDetailsUrl(parcelId) {
    return `${baseUrl}/parcel/${parcelId}/details`;
}

export function getParcelUrl(parcelId) {
    return `${baseUrl}/parcel/${parcelId}`;
}

export function getDetailsClientUrl(parcelId) {
    return `${rootUrl}parcel/${parcelId}`;
}

export function getMapClientUrl(parcelId) {
    return `${rootUrl}map/${parcelId}`;
}

export function getMapLocationClientUrl(latLng) {
    return `${rootUrl}map/${latLng}`;
}

export function getFactsheetUrl(parcelId) {
    return `${baseUrl}/parcel/${parcelId}/factsheet`;
}

export function getCAPEligibilityURL(parcelId) {
    return `${baseUrl}/parcel/${parcelId}/cap`;
}

export function getCurrentChargesUrl() {
    return `${baseUrl}/regulation/current_rates`;
}
