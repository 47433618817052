import { getDetailsUrl } from 'common/settings';
import { runXYSearch, runParcelDetailsIdSearch } from 'common/actions';
import { DETAILS_PAGE, ID_SEARCH } from 'common/constants';

export const SET_PREVIEW_PARCEL = 'SET_PREVIEW_PARCEL';
export const CLEAR_PREVIEW_PARCEL = 'CLEAR_PREVIEW_PARCEL';
export const SET_RATE_PERIOD = 'SET_RATE_PERIOD';
export const CLEAR_DETAILS_STATE_EXCEPT_CAP = 'CLEAR_DETAILS_STATE_EXCEPT_CAP';
export const CLEAR_CAP_DETAILS = 'CLEAR_CAP_DETAILS';

export function setPreviewParcel(location) {
    return {
        type: SET_PREVIEW_PARCEL,
        location,
    };
}

export function clearPreviewParcel() {
    return {
        type: CLEAR_PREVIEW_PARCEL,
        location: null,
    };
}

export const runXYDetailsSearch = (lat, lng) => dispatch =>
    dispatch(runXYSearch(DETAILS_PAGE, lat, lng, setPreviewParcel));

export const runDetailsIdSearch = parcelId => dispatch =>
    dispatch(
        runParcelDetailsIdSearch(
            getDetailsUrl(parcelId),
            DETAILS_PAGE,
            ID_SEARCH,
        ),
    );

export function setRatePeriod(value) {
    return {
        type: SET_RATE_PERIOD,
        value,
    };
}

export function clearDetailsExceptCAP() {
    return {
        type: CLEAR_DETAILS_STATE_EXCEPT_CAP,
    };
}

export function clearCAPDetails() {
    return {
        type: CLEAR_CAP_DETAILS,
    };
}
