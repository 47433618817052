import { CAP_APPLICATION_PAGE } from 'common/constants';

import {
    CAP_POST_SUCCESS,
    CAP_POST_FAILURE,
    CLEAR_CAP_POST_RESPONSE,
} from './actions';

export const initCAPApplicationPageStatus = {
    responseStatus: {
        success: null,
        errorMessage: null,
        formData: null,
    },
};

export function capApplicationPage(
    state = initCAPApplicationPageStatus,
    action,
) {
    if (action.instance === CAP_APPLICATION_PAGE) {
        if (action.type === CAP_POST_SUCCESS) {
            return Object.assign({}, state, {
                responseStatus: {
                    success: true,
                    errorMessage: null,
                    formData: action.data,
                },
            });
        }
        if (action.type === CAP_POST_FAILURE) {
            return Object.assign({}, state, {
                responseStatus: {
                    success: false,
                    errorMessage: action.response,
                    formData: null,
                },
            });
        }
        if (action.type === CLEAR_CAP_POST_RESPONSE) {
            return initCAPApplicationPageStatus;
        }
    }
    return state;
}
