import axios from 'axios';
import head from 'lodash/head';
import findIndex from 'lodash/findIndex';

import { push } from 'react-router-redux';
import {
    ID_SEARCH,
    XY_SEARCH,
    CAP_SEARCH,
    EXPLORE_PAGE,
    DETAILS_PAGE,
    NO_PARCEL_CLICK_MSG,
    NO_PARCEL_ID_MSG,
    NO_CAP_ELIGIBILITY_MSG,
    CAP_PAGE,
    CAP_DETAIL_SEARCH,
} from 'common/constants';
import {
    START_SEARCH,
    SERVER_FAILURE_MSG,
    failSearch,
    completeSearch,
} from 'common/geocoder/actions';
import {
    getParcelUrl,
    getDetailsUrl,
    getDetailsClientUrl,
    getMapClientUrl,
    getXYSearchUrl,
    getCAPEligibilityURL,
    getMapLocationClientUrl,
    getCurrentChargesUrl,
    pvDataMapServerJSONURL,
} from './settings';
import { findCAPAccountIndex } from './utils';

export const ADD_MAP_LAYER = 'ADD_MAP_LAYER';
export const REMOVE_MAP_LAYER = 'REMOVE_MAP_LAYER';
export const SET_CAP_ACCOUNT_INDEX = 'SET_CAP_ACCOUNT_INDEX';
export const CLEAR_CAP_AND_DETAILS = 'CLEAR_CAP_AND_DETAILS';
export const START_SET_MAPSERVER_INDEXES = 'START_SET_MAPSERVER_INDEXES';
export const COMPLETE_SET_MAPSERVER_INDEXES = 'COMPLETE_SET_MAPSERVER_INDEXES';
export const FAIL_SET_MAPSERVER_INDEXES = 'FAIL_SET_MAPSERVER_INDEXES';

export const START_FETCH_CURRENT_RATES = 'START_FETCH_CURRENT_RATES';
export const FAIL_FETCH_CURRENT_RATES = 'FAIL_FETCH_CURRENT_RATES';
export const COMPLETE_FETCH_CURRENT_RATES = 'COMPLETE_FETCH_CURRENT_RATES';

export function startFetchCurrentRates() {
    return {
        type: START_FETCH_CURRENT_RATES,
    };
}

export function failFetchCurrentRates() {
    return {
        type: FAIL_FETCH_CURRENT_RATES,
    };
}

export function completeFetchCurrentRates(rates) {
    return {
        type: COMPLETE_FETCH_CURRENT_RATES,
        rates,
    };
}

export function fetchCurrentRates() {
    return dispatch => {
        dispatch(startFetchCurrentRates());

        return axios
            .get(getCurrentChargesUrl())
            .then(({ data }) => {
                if (!data.IsCurrent) {
                    return dispatch(failFetchCurrentRates());
                }

                return dispatch(completeFetchCurrentRates(data));
            })
            .catch(() => dispatch(failFetchCurrentRates()));
    };
}

export function startXYSearch(url, instance, lat, lng) {
    return {
        type: START_SEARCH,
        searchType: XY_SEARCH,
        url,
        instance,
        lat,
        lng,
    };
}

export function startIdSearch(url, instance) {
    return {
        type: START_SEARCH,
        searchType: ID_SEARCH,
        url,
        instance,
    };
}

export function startCAPSearch(url, instance) {
    return {
        type: START_SEARCH,
        searchType: CAP_SEARCH,
        url,
        instance,
    };
}

function isSearchActive(url, getState) {
    return getState().search.url === url;
}

function navigateToUrl(url) {
    return dispatch => {
        dispatch(push(url));
    };
}

export function navigateToLocation(location) {
    const latLng = `${location.Y}/${location.X}`;
    return navigateToUrl(getMapLocationClientUrl(latLng));
}

export function navigateToDetails(parcel) {
    return navigateToUrl(getDetailsClientUrl(parcel.ParcelID));
}

export function navigateToMap(location) {
    return navigateToUrl(getMapClientUrl(location.Parcel.ParcelID));
}

export function navigateToParcelOrLocation(location) {
    if (location.Parcel) {
        return navigateToMap(location);
    }

    return navigateToLocation(location);
}

export function navigateToDetailsOrLocation(location) {
    if (location.Parcel) {
        return navigateToDetails(location.Parcel);
    }

    return navigateToLocation(location);
}

export function runXYSearch(instance, lat, lng, completeAction) {
    const url = getXYSearchUrl(lat, lng);

    return (dispatch, getState) => {
        function isActive() {
            return isSearchActive(url, getState);
        }

        dispatch(startXYSearch(url, instance, lat, lng));

        return axios
            .get(url)
            .then(({ data }) => {
                if (!isActive()) {
                    return null;
                }

                if (!data.length) {
                    return dispatch(failSearch(NO_PARCEL_CLICK_MSG));
                }

                const location = head(data);

                dispatch(completeSearch(location, instance, XY_SEARCH));

                return completeAction
                    ? dispatch(completeAction(location))
                    : null;
            })
            .catch(() => {
                if (!isActive()) {
                    return null;
                }

                return dispatch(failSearch(SERVER_FAILURE_MSG));
            });
    };
}

export function runXYParcelSearch(lat, lng) {
    return runXYSearch(EXPLORE_PAGE, lat, lng, navigateToMap);
}

export function runParcelDetailsIdSearch(url, instance, searchType) {
    let searchAction = startIdSearch;
    let failureMessage = NO_PARCEL_ID_MSG;
    if (searchType === CAP_SEARCH) {
        searchAction = startCAPSearch;
        failureMessage = NO_CAP_ELIGIBILITY_MSG;
    }
    return (dispatch, getState) => {
        function isActive() {
            return isSearchActive(url, getState);
        }

        dispatch(searchAction(url, instance));

        return axios
            .get(url)
            .then(({ data }) => {
                if (!isActive()) {
                    return null;
                }

                return dispatch(completeSearch(data, instance, searchType));
            })
            .catch(() => {
                if (!isActive()) {
                    return null;
                }

                return dispatch(failSearch(failureMessage));
            });
    };
}

export const runCAPSearch = parcelId => dispatch =>
    dispatch(
        runParcelDetailsIdSearch(
            getCAPEligibilityURL(parcelId),
            DETAILS_PAGE,
            CAP_SEARCH,
        ),
    );

export const runDetailsCAPSearch = parcelId => dispatch =>
    dispatch(
        runParcelDetailsIdSearch(
            getDetailsUrl(parcelId),
            CAP_PAGE,
            CAP_DETAIL_SEARCH,
        ),
    );

export function runParcelIdSearch(parcelId) {
    const url = getParcelUrl(parcelId);
    return runParcelDetailsIdSearch(url, EXPLORE_PAGE, ID_SEARCH);
}

export function findCAPEligibility(details) {
    return runCAPSearch(details.Parcel.ParcelID);
}

export function setCAPAccountIndex(index) {
    return {
        type: SET_CAP_ACCOUNT_INDEX,
        capAccountIndex: index,
    };
}

export function setCAPIndex(accountNumber, accounts) {
    const capAcctIndex = findCAPAccountIndex(accountNumber, accounts);
    return setCAPAccountIndex(capAcctIndex);
}

export function clearCAPAndDetails() {
    return {
        type: CLEAR_CAP_AND_DETAILS,
    };
}

export function removeSelectedMapLayer(layerName) {
    return {
        type: REMOVE_MAP_LAYER,
        layer: layerName,
    };
}

export function addSelectedMapLayer(layerName) {
    return {
        type: ADD_MAP_LAYER,
        layer: layerName,
    };
}

export function startSetMapServerIndexes() {
    return {
        type: START_SET_MAPSERVER_INDEXES,
    };
}

export function completeSetMapServerIndexes(
    parcelsOverlayIndex,
    imperviousOverlayIndex,
) {
    return {
        type: COMPLETE_SET_MAPSERVER_INDEXES,
        parcelsOverlayIndex,
        imperviousOverlayIndex,
    };
}

export function failSetMapServerIndexes() {
    return {
        type: FAIL_SET_MAPSERVER_INDEXES,
    };
}

function parseMapServerLayers(jsonResponse) {
    const { layers } = jsonResponse;
    if (layers) {
        const parcelsOverlayIndex = findIndex(
            layers,
            l => l.name.toLowerCase().indexOf('parcel') !== -1,
        );
        const imperviousOverlayIndex = findIndex(
            layers,
            l => l.name.toLowerCase().indexOf('impervious') !== -1,
        );
        return { parcelsOverlayIndex, imperviousOverlayIndex };
    }
    return {
        parcelsOverlayIndex: -1,
        imperviousOverlayIndex: -1,
    };
}

export function retrieveMapServerIndexes() {
    return dispatch => {
        dispatch(startSetMapServerIndexes());

        return axios
            .get(pvDataMapServerJSONURL)
            .then(({ data }) => {
                const {
                    parcelsOverlayIndex,
                    imperviousOverlayIndex,
                } = parseMapServerLayers(data);

                return dispatch(
                    completeSetMapServerIndexes(
                        parcelsOverlayIndex,
                        imperviousOverlayIndex,
                    ),
                );
            })
            .catch(() => dispatch(failSetMapServerIndexes()));
    };
}
