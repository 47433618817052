import axios from 'axios';
import head from 'lodash/head';

export const GEOCODER_SEARCH = 'GEOCODER_SEARCH';

export const START_SEARCH = 'START_SEARCH';
export const FAIL_SEARCH = 'FAIL_SEARCH';
export const COMPLETE_SEARCH = 'COMPLETE_SEARCH';
export const SET_SEARCH_ERROR = 'SET_SEARCH_ERROR';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const SET_GEOCODER_LOCATIONS = 'SET_GEOCODER_LOCATIONS';
export const PREPROCESS_RESULTS = 'PREPROCESS_RESULTS';

export const SERVER_FAILURE_MSG =
    'There was a server failure. Please try again.';
export const NO_PARCELS_MSG = `We could not find a match for that address. Please
                               try searching for a different address.`;

export function startGeocoderSearch(url, instance) {
    return {
        type: START_SEARCH,
        searchType: GEOCODER_SEARCH,
        url,
        instance,
    };
}

export function failSearch(errorMessage) {
    return {
        type: FAIL_SEARCH,
        errorMessage,
    };
}

export function completeSearch(location, instance, searchType) {
    return {
        type: COMPLETE_SEARCH,
        location,
        instance,
        searchType,
    };
}

export function setSearchError(errorMessage) {
    return {
        type: SET_SEARCH_ERROR,
        errorMessage,
    };
}

export function clearSearch() {
    return {
        type: CLEAR_SEARCH,
    };
}

export function setGeocoderLocations(locations) {
    return {
        type: SET_GEOCODER_LOCATIONS,
        locations,
    };
}

export function isSearchActive(url, instance, getState) {
    const state = getState();
    return state.search.url === url && state.search.instance === instance;
}

export function runGeocoderSearch(
    getSearchUrl,
    instance,
    input,
    selectLocationAction,
    preprocessResults = null,
) {
    const url = getSearchUrl(input);

    return (dispatch, getState) => {
        function isActive() {
            return isSearchActive(url, instance, getState);
        }

        dispatch(startGeocoderSearch(url, instance));

        return axios
            .get(url)
            .then(({ data }) => {
                const results = preprocessResults
                    ? preprocessResults(data)
                    : data;

                if (!isActive()) {
                    return null;
                }

                if (!results.length) {
                    return dispatch(failSearch(NO_PARCELS_MSG));
                }

                if (results.length > 1) {
                    return dispatch(setGeocoderLocations(results));
                }

                const location = head(results);

                dispatch(completeSearch(location, instance, GEOCODER_SEARCH));

                return (
                    selectLocationAction &&
                    dispatch(selectLocationAction(location))
                );
            })
            .catch(() => {
                if (!isActive()) {
                    return null;
                }

                return dispatch(failSearch(SERVER_FAILURE_MSG));
            });
    };
}
