import {
    START_SEARCH,
    GEOCODER_SEARCH,
    COMPLETE_SEARCH,
} from 'common/geocoder/actions';
import { EXPLORE_PAGE, ID_SEARCH, XY_SEARCH } from 'common/constants';

import { SET_ZOOM, SET_CENTER, CLEAR_MAP } from './actions';

export const initExplorePage = {
    center: {
        lat: 39.953,
        lng: -75.16,
    },
    markerPosition: {
        lat: null,
        lng: null,
    },
    parcel: null,
    zoom: 14,
    showMarker: true,
};

export function explorePage(state = initExplorePage, action) {
    if (action.instance === EXPLORE_PAGE) {
        if (action.type === START_SEARCH) {
            if (action.searchType === XY_SEARCH) {
                return Object.assign({}, state, {
                    markerPosition: {
                        lat: action.lat,
                        lng: action.lng,
                    },
                    showMarker: true,
                    parcel: null,
                });
            }
            if (
                action.searchType === GEOCODER_SEARCH ||
                action.searchType === ID_SEARCH
            ) {
                return Object.assign({}, state, {
                    showMarker: false,
                    markerPosition: {
                        lat: null,
                        lng: null,
                    },
                    parcel: null,
                });
            }
            return state;
        }
        if (action.type === COMPLETE_SEARCH) {
            const zoom = action.searchType === ID_SEARCH ? 18 : state.zoom;
            return Object.assign({}, state, {
                center: {
                    lat: action.location.Y,
                    lng: action.location.X,
                },
                markerPosition: {
                    lat: action.location.Y,
                    lng: action.location.X,
                },
                showMarker: true,
                parcel: action.location.Parcel,
                zoom,
            });
        }
    } else if (action.type === SET_ZOOM) {
        return Object.assign({}, state, {
            zoom: action.zoom,
        });
    } else if (action.type === SET_CENTER) {
        return Object.assign({}, state, {
            center: action.center,
        });
    } else if (action.type === CLEAR_MAP) {
        return initExplorePage;
    }

    return state;
}
