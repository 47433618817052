import {
    START_SEARCH,
    FAIL_SEARCH,
    COMPLETE_SEARCH,
    SET_SEARCH_ERROR,
    CLEAR_SEARCH,
    SET_GEOCODER_LOCATIONS,
} from './actions';

export const initSearchState = {
    url: null,
    searchType: null,
    instance: null,
    isFetching: false,
    errorMessage: null,
    locations: null,
    preprocessResults: undefined,
};

export function search(state = initSearchState, action) {
    switch (action.type) {
        case START_SEARCH:
            return Object.assign({}, state, {
                url: action.url,
                searchType: action.searchType,
                instance: action.instance,
                isFetching: true,
                errorMessage: null,
                locations: null,
            });
        case FAIL_SEARCH:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.errorMessage,
            });
        case COMPLETE_SEARCH:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case SET_SEARCH_ERROR:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
            });
        case CLEAR_SEARCH:
            return initSearchState;
        case SET_GEOCODER_LOCATIONS:
            return Object.assign({}, state, {
                isFetching: false,
                locations: action.locations,
            });
        default:
            return state;
    }
}
